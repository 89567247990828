import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import he from 'he';

const BlogListItem = ({ node }) => {
  const category = node.categories[0].name;
  const categorySlug = node.categories[0].slug.toLowerCase();

  return (
    <div className="item">
      <Link
        to={`/news/${categorySlug}/${node.slug}`}
        css={{ textDecoration: `none` }}
      >
        {node.featured_media && (
          <Img
            fluid={node.featured_media.localFile.childCloudinaryAsset.fluid}
            className="gatsby-image-relative"
          />
        )}
        {!node.featured_media && <div className="fpo-image" />}
        <div className="info">
          {node.categories && node.date && (
            <div className="info-top">
              {node.categories && (
                <div className={`category ${categorySlug}`}>
                  {he.decode(category)}
                </div>
              )}
              {node.date && <div className="date">{node.date}</div>}
            </div>
          )}
          <h3 className="post-title">{he.decode(node.title)}</h3>
        </div>
        <div className="background" />
      </Link>
    </div>
  );
};

export default BlogListItem;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, graphql } from 'gatsby';
import he from 'he';
import BlogListItem from '../components/blog/BlogListItem';
import { HeaderContext } from '../context/HeaderContext';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo/SEO';
import iconSearch from '../images/icon-search.svg';
import iconSearchClose from '../images/icon-search-close.svg';
import Layout from '../layouts';

function BlogListTemplate(props) {
  const [state, setState] = useState({
    query: ``,
    results: [],
    searching: false
  });
  const ctxRef = useRef(useContext(HeaderContext));
  const catAllLink = useRef(null);
  let searchInput = useRef(input => {
    searchInput = null;
  });

  useEffect(() => {
    var context = ctxRef;

    context.current.resetLogoDark();
    context.current.setPath(props.location.pathname);

    return () => {
      context.current.setPath('');
    }
  }, [props.location.pathname]);

  function toggleSearch() {
    let { query, searching, results } = { ...state };
    searching = !searching;
    setState({ ...state, searching });
    
    if (searching) {
      searchInput.focus();
    } else {
      query = '';
      results = [];
      setState({ ...state, query, results, searching });
      searchInput.blur();
    }
  };

  function handleKeyDown(event) {
    let { searching } = { ...state };
    // if escape key and searching
    if (event.keyCode === 27 && searching === true) {
      toggleSearch();
    }
  };

  function handleCategoryClick(e) {
    let { searching } = { ...state };
    if (searching === true) {
      toggleSearch();
    }
  };

  const posts = props.data.allWordpressPost.edges;
  const {
    currentPage,
    numPages,
    category,
    categoryName,
    author,
    authorName,
    tag,
    tagName
  } = props.pageContext;

  const tags = props.data.allWordpressTag.edges;

  // Set categories to specific order
  const categoryEdges = props.data.allWordpressCategory.edges;
  let categories = [];
  let categoryOrder = [
    'our-stories',
    'views',
    'announcements',
    'creative',
    'digital-marketing',
    'strategy'
  ];
  categoryOrder.forEach(order => {
    categoryEdges.forEach(edge => {
      if (order === edge.node.slug) {
        categories.push(edge);
      }
    });
  });

  //
  // Set prev / next links
  //
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  // default
  let prevLink = `/news/${prevPage}`;
  let nextLink = `/news/${nextPage}`;
  if (category) {
    // category
    prevLink = `/news/${category}/${prevPage}`;
    nextLink = `/news/${category}/${nextPage}`;
  } else if (tag) {
    // tags (topics)
    prevLink = `/news/topics/${tag}/${prevPage}`;
    nextLink = `/news/topics/${tag}/${nextPage}`;
  } else if (author){
    prevLink = `/news/author/${author}/${prevPage}`;
    nextLink = `/news/author/${author}/${nextPage}`;
  }

  let pageTitle = 'News';
  if (categoryName) {
    pageTitle = categoryName;
  } else if (tagName) {
    pageTitle = `# ${tagName}`;
  } else if (authorName) {
    pageTitle = authorName;
  }

  const activeHome = pageTitle === 'News' ? 'active' : '';
  const thisPath = props.location.pathname;
  const blogClass =
    (thisPath === '/news' || thisPath === '/news/') &&
    state.results.length === 0
      ? 'blog blog-home'
      : 'blog';

  const placeHolderText = (tagName) ? 
  (
    <div>
      <span>#</span> {he.decode(tagName)}
    </div>      
  )
  :
  authorName ?
    (
      <div>{authorName}</div>
    )
    :
    (
      'Search'
    );
  
  function getSearchResults(query) {
    if (!query || !window.__LUNR__) return [];
    const lunrIndex = window.__LUNR__['en'];
    const results = lunrIndex.index.search(query); // you can  customize your search , see https://lunrjs.com/guides/searching.html
    return results.map(({ ref }) => lunrIndex.store[ref]);
  }

  function search(event) {
    const query = event.target.value;
    const results = getSearchResults(query);
    const searching = state.searching
    
    setState(s => {
      return {
        results,
        query,
        searching
      };
    });
  };
  
  const site = props.data.site.siteMetadata;

  return (
    <Layout>
      <Helmet>
        <title>{he.decode(pageTitle)} | Firewood</title>
      </Helmet>
      {tag || category ? (
        <SEO node={props.pageContext} site={site} />
      ) : (
        <SEO node={props.data.wordpressPage} site={site} />
      )}
      <div className={blogClass}>
        <section
          className={`section-search-field searching-${state.searching}`}
        >
          <div className="section-search-field--main">
            <div id="input-nav">
              <div className="placeholder-input">
                <div id="search-button" role="button" tabIndex="0" onClick={toggleSearch} onKeyPress={null}>
                  <img src={iconSearch} id="icon-search" alt="search icon" />
                  <img
                    src={iconSearchClose}
                    id="icon-search-close"
                    alt="search close icon"
                  />
                </div>
                <div className="placeholder" role="searchbox" tabIndex="0" onClick={toggleSearch} onKeyUp={null}>
                  {placeHolderText}
                </div>
                <input
                  type="text"
                  value={state.query}
                  onChange={search}
                  onKeyDown={handleKeyDown}
                  ref={input => {
                    searchInput = input;
                  }}
                />
              </div>
              <div className="category-nav">
                <Link
                  to="/news"
                  className={`category-link ${activeHome}`}
                  activeClassName="active"
                  ref={catAllLink}
                >
                  All
                </Link>
                {categories.map(({ node }) => {
                  const { id, slug, name, count } = node;
                  const isActive =
                    name !== null && name === categoryName ? 'active' : '';

                  if (count === 0 || slug === 'uncategorized') return null;
                  return (
                    <Link
                      key={id}
                      to={`/news/${slug}`}
                      className={`category-link ${slug} ${isActive}`}
                      activeClassName="active"
                      onClick={handleCategoryClick}
                    >
                      {he.decode(name)}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <div className="blog-list">
          {state.query
            ? state.results.map(page => {
                return <BlogListItem key={page.slug} node={page} />;
              })
            : posts.map(({ node }) => {
                return <BlogListItem key={node.slug} node={node} />;
              })}
        </div>
        <section className="section-body section-pagination">
          <div className="content">
            <div className="content--main">
              {!state.query && !isFirst && (
                <Link
                  to={prevLink}
                  rel="prev"
                  className="pagination-link prev"
                >
                  <i className="fas fa-arrow-left" />
                  <div className="text">
                    Previous <span>Articles</span>
                  </div>
                </Link>
              )}
              {!state.query && !isLast && (
                <Link
                  to={nextLink}
                  rel="next"
                  className="pagination-link next"
                >
                  <div className="text">
                    Next <span>Articles</span>
                  </div>
                  <i className="fas fa-arrow-right" />
                </Link>
              )}
            </div>
          </div>
        </section>
        {tags && (
          <section className="section-body section-tags">
            <div className="content">
              <div className="content--main">
                <h6 className="title">Topics</h6>
                {tags.map(({ node }) => {
                  const { id, slug, name, count } = node;
                  if (count === 0) return null;
                  return (
                    <Link key={id} to={`/news/topics/${slug}`}>
                      <span>#</span> {he.decode(name)}
                    </Link>
                  );
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
}

export default BlogListTemplate;

export const blogListQuery = graphql`
  query blogListQuery($nodes: [String], $skip: Int!, $limit: Int!) {
    allWordpressPost(
      filter: { fields: { ids: { in: $nodes } } }
      sort: { fields: [sticky, date], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          slug
          sticky
          categories {
            name
            slug
          }
          tags {
            name
            slug
          }
          featured_media {
            source_url
            localFile {
              childCloudinaryAsset {
                id
                fluid(maxWidth: 960, transformations: ["ar_16:9","c_fill"], chained: ["w_auto,c_scale","c_limit"]) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressCategory {
      edges {
        node {
          id
          name
          slug
          count
        }
      }
    }
    allWordpressTag {
      edges {
        node {
          id
          name
          slug
          count
        }
      }
    }
    wordpressPage(title: { eq: "News" }) {
      ...SEO
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;